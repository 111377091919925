<div id="userPopUp" *ngIf="showPopUp" [class.showUp]="showCont">
    <div class="backLayer" (click)="choosePopUp()"></div>

    <div class="popUpBox" [class.userProfile]="popUp.profile" [class.slideUp]="showBox"
        *ngIf="popUp.pushNotif || popUp.insertMsisdn || popUp.insertPin || popUp.profile || popUp.subscribe || popUp.subscribeSuccess || popUp.subscribeError || popUp.noCredit || popUp.subscribePending">
        
        <header *ngIf="popUp.pushNotif && !!global.propConfs$.portalLogo"><img [src]="global.propConfs$.portalLogo" alt="Logo Playbox"></header>
        <main *ngIf="popUp.pushNotif" class="acceptPush">
            <h3>{{ 'user.getNews' | translate }}</h3>
            <button class="btn sec" (click)="true">{{ 'user.yes' | translate }}</button>
            <a (click)="choosePopUp()">{{ 'user.cancel' | translate }}</a>
        </main>

        <main *ngIf="popUp.insertMsisdn" class="insertMsisdn">
            <h3>{{ 'user.hey' | translate }}</h3>
            <h4>{{ 'user.areYouReady' | translate }}</h4>
            <h3>{{ 'user.loginNow' | translate }}</h3>
            <h5>{{ 'user.insertNumber' | translate }}</h5>
            <div class="inputBox msisdnInput">
                <span *ngIf="!!global.propConfs$.prefix">{{ global.propConfs$.prefix }}</span><input #userMsisdn type="tel" maxlength="14" (keyup.enter)="requestNewPin(userMsisdn.value)" (focus)="incorrectMsisdn = false" [disabled]="pendingCall">
            </div>
            <span *ngIf="incorrectMsisdn" class="invalidVal">{{ 'user.invalidNum' | translate }}</span>
            <button class="btn sec" (click)="requestNewPin(userMsisdn.value)" [class.mt-3]="incorrectMsisdn" [disabled]="pendingCall">{{ 'user.continue' | translate }}</button>
            <span [innerHTML]="'user.receiveSms' | translate"></span>
        </main>

        <main *ngIf="popUp.insertPin" class="insertPin">
            <h3>{{ 'user.enter' | translate }}</h3>
            <span>{{ 'user.checkSms' | translate }}</span>
            <h4>{{ 'user.insertPin' | translate }}</h4>
            <div class="inputBox pinInput">
                <span>{{ 'user.pin' | translate }}</span><input #userPin type="number" maxlength="4" (keyup.enter)="pinCheck(userPin.value)" (focus)="incorrectPin = false" [disabled]="pendingCall">
            </div>
            <span *ngIf="incorrectPin" class="invalidVal">{{ 'user.invalidPin' | translate }}</span>
            <button class="btn sec" (click)="pinCheck(userPin.value)" [class.mt-3]="incorrectPin" [disabled]="pendingCall">{{ 'user.ok' | translate }}</button>
        </main>

        <main *ngIf="popUp.profile" class="profile">
            <h3>{{ 'user.welcome' | translate }}</h3>
            <div class="userImg"><i class="fas fa-user-circle"></i></div>
            <p *ngIf="global.propConfs$.userName">{{ 'userName' }}</p>
            <p *ngIf="global.propConfs$.msisdn">{{ userMsisdn }}</p>

            <h6 *ngIf="subscriptionPending" class="my-4">{{ 'pendingSubscription' | translate }}</h6>

            <select #selectClub *ngIf="!subscriptionPending && isUserSubscribed === false && !!!global.propConfs$.outsideSubscription" [hidden]="portalClubs$?.length <= 1">
                <option *ngFor="let c of portalClubs$" [value]="c.productId">{{ c.productName }}</option>
            </select>
            
            <button *ngIf="global.propConfs$.pushNotif && false" class="btn sec" (click)="beh._userPopUp.next('pushNotif')" [disabled]="pendingCall">{{ 'user.allowNotifications' | translate }}</button>

            <button *ngIf="isUserSubscribed === true && global.propConfs$.allowUnsubscription && global.propConfs$.subscriptionNeeded" class="btn sec unsub" (click)="unsubscribe()" [disabled]="pendingCall">{{ 'user.unsubscribe' | translate }}</button>            
            <button *ngIf="!subscriptionPending && isUserSubscribed === false && !!!global.propConfs$.outsideSubscription && global.propConfs$.subscriptionNeeded && global.propConfs$.allowSubscription" class="btn sec" (click)="subscribe()" [disabled]="pendingCall">{{ 'user.subscribe' | translate }}</button>
            <a *ngIf="!subscriptionPending && isUserSubscribed === false && !!global.propConfs$.outsideSubscription && global.propConfs$.subscriptionNeeded" class="btn sec" (click)="subscribe()" [href]="global.propConfs$.outsideSubscription" target="_blank">{{ 'user.subscribe' | translate }}</a>

            <a *ngIf="global.propConfs$.allowLogout" (click)="logout()">{{ 'user.logout' | translate }}</a>
        </main>

        <main *ngIf="popUp.subscribe" class="subscribe">
            <h3>{{ 'user.subscribeNow' | translate }}</h3>
            
            <select #selectClub *ngIf="!!!global.propConfs$.outsideSubscription" [hidden]="portalClubs$?.length <= 1">
                <option *ngFor="let c of portalClubs$" [value]="c.productId">{{ c.productName }}</option>
            </select>

            <button *ngIf="!!!global.propConfs$.outsideSubscription && !!global.propConfs$.allowSubscription" class="btn sec" (click)="subscribe()" [disabled]="pendingCall">{{ 'user.subscribe' | translate }}</button>
            <a *ngIf="!!global.propConfs$.outsideSubscription && !!global.propConfs$.allowSubscription" class="btn sec" (click)="subscribe()" [href]="global.propConfs$.outsideSubscription" target="_blank">{{ 'user.subscribe' | translate }}</a>
        </main>

        <main *ngIf="popUp.subscribeSuccess" class="success">
            <span class="icons"><i class="far fa-check-circle"></i></span>
            <h3>{{ 'user.success' | translate }}</h3>
        </main>

        <main *ngIf="popUp.subscribeError" class="error">
            <span class="icons"><i class="far fa-times-circle"></i></span>
            <h3>{{ 'user.error' | translate }}</h3>
        </main>

        <main *ngIf="popUp.noCredit" class="warn">
            <span class="icons"><i class="fas fa-exclamation-circle"></i></span>
            <h3>{{ 'user.noCredit' | translate }}</h3>
        </main>

        <main *ngIf="popUp.subscribePending" class="warn">
            <span class="icons"><i class="fas fa-info-circle"></i></span>
            <h3>{{ 'pendingSubscription' | translate }}</h3>
        </main>

        <div class="closePopUp" (click)="choosePopUp()"><i class="fas fa-times"></i></div>
    </div>

</div>